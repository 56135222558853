'use strict'

angular.module('theme.dashboard', [])
	.controller('DashboardController', ["$rootScope", "$scope", "$modal", "$location", "NotificationService", "LeadProfileService", "MemoDetailService", "SaleOrderService", function ($rootScope, $scope, $modal, $location, NotificationService, LeadProfileService, MemoDetailService, SaleOrderService) {

		$scope.$on('UserPopulated', () => {
            $scope.init();
		});
		
		$scope.init = function() {
			if ($rootScope.User) {
				$scope.IsMarketingOrSales = $rootScope.User.Roles.filter(item => item.Name == 'Sales Desk' || item.Name == 'Marketing' || item.Name == 'Partnership').length > 0 ? true : false;
				$scope.ActionsPageNumber = 1;
				$scope.ActionsPagination = {};

				$scope.UrgentOrdersPageNumber = 1;
				$scope.UrgentOrdersPagination = {};

				$scope.IsAreaManager = $rootScope.User.Roles.filter(item => item.Name == 'Area Manager').length > 0 ? true : false;
			};
		};

		$scope.$watch('ActionsPageNumber', function () {
			if ($scope.IsMarketingOrSales == false)
				$scope.getActions();
		});
		
		$scope.$watch('UrgentOrdersPageNumber', function () {
			if ($scope.IsAreaManager == true)
				$scope.getUrgentOrders();
        });

		$scope.getActions = function () {
			var data = {
                Pagination: {
                    StartPage: $scope.ActionsPageNumber - 1,
                    PageSize: 15
                }
			};
			
			$scope.GetActionPromise = LeadProfileService.getDashboardActions(data);
			$scope.GetActionPromise.then(function (output) {
				$scope.Actions = output.data.Actions;
				$scope.ActionsPagination = output.data.Pagination;
			}, function (error) {
				$scope.fetchedDataError = true;
				NotificationService.alert('Actions', 'There was an error retrieving the pending actions.', 'error', {});
			});
		};

		$scope.getUrgentOrders = function () {
			var data = {
                Pagination: {
                    StartPage: $scope.UrgentOrdersPageNumber - 1,
                    PageSize: 15
                }
			};

			$scope.gettingUrgentOrdersPromise = SaleOrderService.getUrgentOrders(data);
			$scope.gettingUrgentOrdersPromise.then(orders => {
				$scope.urgentOrders = orders.data.Orders;
				$scope.UrgentOrdersPagination = orders.data.Pagination;
			}, err => {
				NotificationService.alert('Urgent Orders', 'There was an error retrieving the list of urgent orders.', 'error', {});
			});
		};

		$scope.OpenView = function (memo) {
			var url = '/' + memo.SentFromUrl + '/' + memo.SentFromId;
			$location.url(url);
			$scope.close();
		};

		$scope.openReminder = function (item) {
			$scope.MemoPromice = MemoDetailService.getMemoById(item.Id);
			$scope.MemoPromice.then((output) => {
				item = output.data;
				item.RelatedObjectId = item.SentFromId;
				item.RelatedObject = item.SentFromUrl;
				item.Parent= item.MemoId;
				var Data = {
					Reminder: item,
					Type: 'Reminder',
					SentId: null,
					ReplyingToReminder: true,
				}
				var modalInstance = $modal.open({
					animation: true,
					size: 'lg',
					backdrop: 'static',
					templateUrl: 'views/modal-templates/add-memo.html',
					controller: 'SendMemoController',
					resolve: {
						data: () => {
							return Data;
						}
					}
				});
				modalInstance.result.then(function (data) {
					if (data.Type == 'Alerts')
						$scope.getAlerts();
				});
				modalInstance.result.then(function () {
					NotificationService.alert("Reminder Closed", "You have closed the memo.", "success", {});
				});
			}, (error) => {

			});
		};
	}])

	.controller('ReminderModalController', ["$scope", "$modal", "$modalInstance", "NotificationService", "MemoDetailService", "MemoId", "$location", function ($scope, $modal, $modalInstance, NotificationService, MemoDetailService, MemoId, $location) {
		$scope.MemoId = MemoId;

		$scope.init = function () {
			$scope.memo = {};
			$scope.remindDate = "";
			$scope.memoThread = [];
			$scope.threadShow = false;
			$scope.message = "";
			$scope.replyShow = false;
			$scope.getReminder($scope.MemoId);
		};

		$scope.dateMask = function () {
			var date = $scope.remindDate;
			if (date.match(/^\d{2}$/) !== null) {
				$scope.remindDate = date + '/';
			} else if (date.match(/^\d{2}\x2F\d{2}$/) !== null) {
				$scope.remindDate = date + '/';
			}
		};
		$scope.open = function () {
			var url = '/' + $scope.memo.SentFromUrl + '/' + $scope.memo.SentFromId;
			$location.url(url);
			$scope.close();
		};

		$scope.getReminder = function (id) {
			$scope.gettingMemoPromise = MemoDetailService.getMemoById(id);
			$scope.gettingMemoPromise.then(function (output) {
				$scope.memo = output.data;
				debug;
			}, function (error) {
				NotificationService.alert('Reminders', 'There was an error retrieving reminders.', 'error', {});
			});
		};

		$scope.close = function () {
			$modalInstance.dismiss('close');
		};

		//function for marking the memo as read
		$scope.markAsRead = function (id) {
			$scope.markAsReadPromise = MemoDetailService.markMemoAsRead({
				MemoId: id
			});
			$scope.markAsReadPromise.then(function (output) {
				NotificationService.alert("Reminder Read", "Reminder now marked as read.", "success", {});
				$scope.close();
			}, function (error) {
				NotificationService.alert("Error!", "There has been an error.", "error", {});
			});
		};

		//function for replying to the memo
		$scope.reply = function (id) {
			//var data = {
			//    Message: $scope.message,
			//    Sender: , //need to get sender id/name?
			//    Time: new Date()
			//};

			$scope.replyToMemoPromise = MemoDetailService.replyToMemo(id, data);
			$scope.replyToMemoPromise.then(function (output) {
				NotificationService.alert("Reply Sent", "Your reply has been sent.", "success", {});
				$scope.close();
			}, function (error) {
				NotificationService.alert("Error!", "There has been an error.", "error", {});
			});
		};

		//function for getting the thread of messages associated with a memo
		$scope.getMemoThread = function (id) {
			$scope.gettingMemoThreadPromise = MemoDetailService.getMemoThread(id);
			$scope.gettingMemoThreadPromise.then(function (output) {
				$scope.memoThread = output.data;
				NotificationService.alert("Memo Thread", "Memo thread received.", "success", {});
			}, function (error) {
				NotificationService.alert("Error!", "There has been an error.", "error", {});
			});
		};
		$scope.remindMe = function (Id, date) {
			var data = {
				MemoId: Id,
				RemindOn: date
			}
			$scope.memoRemindMePromise = MemoDetailService.remindMe(data);
			$scope.memoRemindMePromise.then(function (output) {
				NotificationService.alert("Reply Sent", "You will be reminded.", "success", {});
				$scope.close();
			}, function (error) {
				NotificationService.alert("Error!", "There has been an error.", "error", {});
			});

		};

	}])
